@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@800&family=Belanosima&display=swap');
@import '../../constants/constants.scss';


.about-title {
    font-family: 'Abhaya Libre', serif;
    font-size: 8em;
    @media (max-width: 700px) {
        font-size: 4em;
      }
      transition-duration: 1s;
      &:hover {
        letter-spacing: 10px;
      }
}

@mixin title-name {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #000;
    text-align: center;
    letter-spacing: 0.2rem;
    font-family: 'Belanosima', sans-serif;
}
.about-name{
    @include title-name;
    @media (max-width: 700px) {
        font-size: 2em;
      }
      transition-duration: 1s;
      &:hover {
        letter-spacing: 6px;
      }
}

.general-text {
    font-family: 'Belanosima', sans-serif;
    font-size: 1.5em;
    @media (max-width: 700px) {
        font-size: 1em;
      }
}

#about-title-container {
    display: flex;
    flex-direction: row;
    text-align: center;
    @media (max-width: 920px) {
        width: 50%;
      }
      @media (max-width: 965px) {
        width: 85%;
      }
      @media (max-width: 700px) {
        width: 95%;
      }

    // &:before {
    //   content: '';
    //   margin-top: 30px;
    //   margin-right: 10px;
    //   display: block;
    //   width: 20%;
    //   height: 2px;
    //   background-color: #fffff0;
    // }

    // &:after {
    //   content: '';
    //   margin-top: 30px;
    //   margin-left: 10px;
    //   display: block;
    //   width: 70%;
    //   height: 2px;
    //   background-color: #fffff0;
    // }
}
.about-components {
    @media (max-width: 600px) {
        width: 80%;
      }
}

