@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@800&family=Belanosima&display=swap');

.Landing-Name {
    font-family: 'Abhaya Libre', serif;
    font-size: 8em;
    color: black;
    transition-duration: 1s;
    // -webkit-background-clip: text;
    @media (max-width: 950px) {
        font-size: 80px;
      }

      @media (max-width: 770px) {
        font-size: 70px;
      }

      @media (max-width: 600px) {
        margin-left: 10px;
        font-size: 60px;
      }
      &:hover {
        letter-spacing: 10px;
      }

}

.Landing-Position {
    font-family: 'Belanosima', sans-serif;
    font-size: 5em;
    color: rgba(0, 0, 0, 0.75);
    transition-duration: 1s;

    @media (max-width: 950px) {
        font-size: 50px;
      }

      @media (max-width: 770px) {
        font-size: 40px;
      }

      @media (max-width: 600px) {
        margin-left: 10px;
        font-size: 40px;
      }
      &:hover {
        letter-spacing: 8px;
      }
}

.landing-inner-container {
    width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 920px) {
        width: 50%;
      }
      @media (max-width: 965px) {
        width: 85%;
      }
      @media (max-width: 700px) {
        width: 95%;
      }
  
}

