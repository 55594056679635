@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@800&family=Belanosima&display=swap');

.fade {
    position: fixed;
    z-index:2; //any higher integer value
    // background-color: #c6d5c6;
    // background-color: #AA96DA;
    // background-color: #ADD8E6;
    background-color: #c0d0c0;
    // background-color: #0A192F;
    
}

.navbar-main-container {
    font-family: 'Belanosima', sans-serif;
    font-size: 2em;
    @media (max-width: 500px) {
        font-size: 1.2em;
      }
}


.nav-item, .nav-email {
    position: relative;
    display: block;
    cursor: pointer;
    // color:  #3A6B35;
}

.nav-item:before, .nav-item:after {
    content: '';
    position: absolute;
    width: 0%;
    height: 4px;
    top: 50%;
    margin-top: -0.5px;
    background: #000;
}

.nav-item:before {
    left: -2.5px;
}
.nav-item:after {
    right: 2.5px;
    background: #000;
    transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.nav-item:hover:before {
    background: #000;
    width: 100%;
    transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);   
}

.nav-item:hover:after {
    background: transparent;
    width: 100%;
    transition: 0s;
}

.nav-email {
    @media (max-width: 1024px) {
        display: none;
        }
}




