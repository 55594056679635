// @import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@800&family=Belanosima&display=swap');
// @import '../../constants/constants.scss';

// .experience-name {
//     font-family: 'Abhaya Libre', serif;
//     font-size: 8em;
// }



// .outer-experience-container {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-top: 325px;



//   #experience-main-container {
//     width: 900px;
//     border-radius: 0.75em;

//     @media (max-width: 965px) {
//       width: 85%;
//     }
//     @media (max-width: 920px) {
//       width: 80%;
//     }
//     @media (max-width: 700px) {
//       width: 70%;
//     }

//     #experience-header-container {
//       display: flex;
//       flex-direction: row;
//       text-align: center;
//       margin-bottom: 15px;
//       justify-content: center;

//     //   &:before {
//     //     content: '';
//     //     margin-top: 30px;
//     //     margin-right: 10px;
//     //     display: block;
//     //     width: 20%;
//     //     height: 2px;
//     //     background-color: $themeWhite;
//     //   }

//     //   &:after {
//     //     content: '';
//     //     margin-top: 30px;
//     //     margin-left: 10px;
//     //     display: block;
//     //     width: 70%;
//     //     height: 2px;
//     //     background-color: $themeWhite;
//     //   }

//     //   #experience-header {
//     //     @include section-header-red;
//     //   }
//     }

//     #experience-object-container {
//       display: flex;
//       border-bottom: 1px solid rgba(255, 255, 255, 0.3);
//     //   background-color: rgba(15, 15, 15, 0.9);
//     //   background-color: rgba(192, 208, 192, 1);
//       // what is background-color: #c0d0c0; in rgba?
//       background-color: rgba(182, 198, 182, 1);

//       #experience-image-container {
//         margin: 27px 10px 10px 10px;
//         #experience-image {
//           min-width: 80px;
//           min-height: 80px;
//           max-width: 80px;
//           max-height: 80px;
//         }
//       }

//       #experience-text-container {
//         margin: 15px 0px 15px 0px;

//         #experience-title {
//           margin: 0px 10px 0px 10px;
//          color: $themeBlack;
//           font-size: 2em;
//           font-family: 'Belanosima', sans-serif;
//         }

//         #experience-employer {
//           margin: 0px 10px 10px 10px;
//           color: $themeBlack;
//           font-size: 1.5em;
//           font-family: 'Belanosima', sans-serif;
//         }

//         #experience-date {
//           @include paragraph-gray-text;
//           font-size: 1.25em;
//           margin: 10px 10px 10px 10px;
//           font-family: 'Belanosima', sans-serif;
//         }

//         #experience-description {
//           @include paragraph-gray-text;
//           margin: 10px 10px 10px 10px;

//         }
//       }
//     }
//   }
// }

@import '../../constants/constants.scss';

@mixin title-font {
  font-family: 'Belanosima', sans-serif;
}
.experience-name {
  font-family: 'Abhaya Libre', serif;
  font-size: 8em;
  @media (max-width: 700px) {
    font-size: 4em;
  }
  transition-duration: 1s;
  &:hover {
    letter-spacing: 6px;
  }
}


#outer-experience-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  margin-top: 325px;

  #experience-main-container {
    width: 900px;

    @media (max-width: 920px) {
      width: 50%;
    }
    @media (max-width: 965px) {
      width: 85%;
    }
    @media (max-width: 700px) {
      width: 95%;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;
    }

    #experience-header-container {
      display: flex;
      flex-direction: row;
      text-align: center;
      margin-bottom: 15px;
      justify-content: center;

      // &:before {
      //   content: '';
      //   margin-top: 30px;
      //   margin-right: 10px;
      //   display: block;
      //   width: 20%;
      //   height: 2px;
      //   background-color: $themeWhite;
      // }

      // &:after {
      //   content: '';
      //   margin-top: 30px;
      //   margin-left: 10px;
      //   display: block;
      //   width: 70%;
      //   height: 2px;
      //   background-color: $themeWhite;
      // }

      // #experience-header {
      //   @include section-header-red;
      // }
    }
    .experience-object {
      overflow: hidden;
      @media (max-width: 700px) {
        font-size: 0.75em;
      }
      

      #experience-object-container {
        display: flex;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        // background-color: rgba(15, 15, 15, 0.9);
        background-color: rgba(182, 198, 182, 1);

        #experience-image-container {
          margin: 27px 10px 10px 10px;
          #experience-image {
            min-width: 80px;
            min-height: 80px;
            max-width: 80px;
            max-height: 80px;
          }
        }

        #experience-text-container {
          margin: 15px 0px 15px 0px;
          #experience-title {
            margin: 0px 10px 0px 10px;
            // color: $themeRed;
            font-size: 2em;
            transition-duration: 1s;
            &:hover {
              letter-spacing: 6px;
            }
            @include title-font;
          }

          #experience-employer {
            margin: 0px 10px 10px 10px;
            // color: $themeYellow;
            font-size: 1.5em;
            @include title-font;
          }

          #experience-date {
            @include paragraph-gray-text;
            margin: 10px 10px 10px 10px;
            font-size: 1.25em;
            @include title-font;
          }

          #experience-description {
            @include paragraph-gray-text;
            margin: 10px 10px 10px 10px;
            @include title-font;
          }
        }
      }
    }
  }
}