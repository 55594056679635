@import "../../constants/constants";

.left {
  width: 40px;
  position: fixed;
  bottom: 0;
  left: 40px;
  animation-name: fade-in;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;

  @media screen and (max-width: 1230px) {
    display: none;
  }
}

.right {
  width: 40px;
  position: fixed;
  bottom: 0;
  right: 40px;
  animation-name: fade-in;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  z-index: 1;

  @media (max-width: 1230px) {
    display: none;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}